import React from "react";
import "./datenschutz.css";
import { Helmet } from 'react-helmet'
function Datenschutz() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Datenschutz</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/datenschutz" />
      </Helmet>
      <h1 className="big-point">1. Datenschutz auf einen Blick</h1>
      <h2 className="second-big">Allgemeine Hinweise</h2>
      <p className="normal-big">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen zum
        Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h2 className="second-big">Datenerfassung auf unserer Seite</h2>
      <h2 className="second-big">
        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
      </h2>
      <p className="normal-big">
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>
      <h2 className="second-big">Wie erfassen wir Ihre Daten?</h2>
      <p className="normal-big">
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
        <br />
        <br />
        Andere Daten werden automatisch beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
        Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
        betreten.
      </p>
      <h2 className="second-big">Wofür nutzen wir Ihre Daten?</h2>
      <p className="normal-big">
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <h2 className="second-big">
        Welche Rechte haben Sie bezüglich Ihrer Daten?
      </h2>
      <p className="normal-big">
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
        Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum
        Thema Datenschutz können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <h2 className="second-big">Analyse-Tools und Tools von Drittanbietern</h2>
      <p className="normal-big">
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit Cookies und mit
        sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt
        in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
        zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie
        durch die Nichtbenutzung bestimmter Tools verhindern. Details hierzu
        entnehmen Sie unserer Datenschutzerklärung unter der Überschrift
        “Drittmodule und Analysetools”.
        <br />
        <br />
        Sie können dieser Analyse widersprechen. Über die
        Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
        informieren.
      </p>
      <h1 className="big-point">
        2. Allgemeine Hinweise und Pflichtinformationen
      </h1>
      <h2 className="second-big">Datenschutz</h2>
      <p className="normal-big">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
        <br />
        <br />
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        <br />
        <br />
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h2 className="second-big">Hinweis zur verantwortlichen Stelle</h2>
      <p className="normal-big">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
        <br />
        <br />
        Birgit Kürten
        <br />
        Alpspitzweg 8<br />
        85551 Kirchheim b. München
        <br />
        <br />
        Telefon: 0178 / 2702690
        <br />
        E-Mail: birgit@mydoula-muenchen.de
        <br />
        <br />
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
        Ä.) entscheidet.
      </p>
      <h2 className="second-big">
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </h2>
      <p className="normal-big">
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <h2 className="second-big">
        Beschwerderecht bei der zuständigen Aufsichtsbehörde
      </h2>
      <p className="normal-big">
        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
        Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
        seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
        Kontaktdaten können folgendem Link entnommen werden:{" "}
        <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </p>
      <h2 className="second-big">Auskunft, Sperrung, Löschung</h2>
      <p className="normal-big">
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
      </p>
      <h2 className="second-big">Widerspruch gegen Werbe-Mails</h2>
      <p className="normal-big">
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h1 className="big-point">3. Datenerfassung auf unserer Website</h1>
      <h2 className="second-big">Cookies</h2>
      <p className="normal-big">
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
        <br />
        <br />
        Die meisten der von uns verwendeten Cookies sind so genannte
        “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
        <br />
        <br />
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
        <br />
        <br />
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
        (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
        andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>
      <h2 className="second-big">Server-Log-Dateien</h2>
      <p className="normal-big">
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <ul className="impressum-ul">
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p className="normal-big">
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
        <br />
        <br />
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>
      <h2 className="second-big">Kontaktformular</h2>
      <p className="normal-big">
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
        <br />
        <br />
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu
        reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
        der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.
        <br />
        <br />
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h2 className="second-big">Kommentarfunktion auf dieser Website</h2>
      <p className="normal-big">
        Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar
        auch Angaben zum Zeitpunkt der Erstellung des Kommentars und, wenn Sie
        nicht anonym posten, der von Ihnen gewählte Nutzername gespeichert.
      </p>
      <h2 className="second-big">Speicherdauer der Kommentare</h2>
      <p className="normal-big">
        Die Kommentare und die damit verbundenen Daten (z.B. IP-Adresse) werden
        gespeichert und verbleiben auf unserer Website, bis der kommentierte
        Inhalt vollständig gelöscht wurde oder die Kommentare aus rechtlichen
        Gründen gelöscht werden müssen (z.B. beleidigende Kommentare).
      </p>
      <h2 className="second-big">Rechtsgrundlage</h2>
      <p className="normal-big">
        Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung
        (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
        Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
        per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
      <p className="normal-big">
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google:{" "}
        <a href="https://www.google.de/intl/de/policies/privacy/">
          https://www.google.de/intl/de/policies/privacy/
        </a>
        .
      </p>
    </div>
  );
}

export default Datenschutz;
