import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import './guestbook.css'
function Eintragen() {

    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [statusCode, setStatusCode] = useState('');
    const [ip, setIP] = useState('');

  

    useEffect(() => {
                
        fetch('https://geolocation-db.com/json/').then(res2 => res2.json()).then(result => setIP(result.IPv4))

    })
    const recaptchaRef = React.createRef();

    const onCaptchaExecute = async (e) => {
        e.preventDefault();
        await recaptchaRef.current.executeAsync();
        try {
            const token = recaptchaRef.current.getValue();
            recaptchaRef.current.reset();

            let res = await fetch('/api/guestbook-new-submit', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    message: message.replace(/\n/g, '<br />'),
                    requestIP: ip,
                    captcha: token
                })
            })
            if (res.status === 200) {

                res.json().then(result => {
                    setName('')
                    setMessage('')
                    setStatusCode(result.data)

                })

            } else if(res.status === 403) {

                res.json().then(result => {
                    setStatusCode(result.data)
                })

            } else {

                setStatusCode("Fehler beim Senden")
            }

        } catch (err){
            console.log(err)
        }

    }

    
    
    
    

    let handleSubmit = async (e) => {
        e.preventDefault()


        try {
            const token = recaptchaRef.current.getValue();
            console.log(token);

            let res = await fetch('/api/guestbook-new-submit', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    message: message.replace(/\n/g, '<br />'),
                    requestIP: ip,
                    captcha: token
                })
            })
            if (res.status === 200) {

                res.json().then(result => {
                    setName('')
                    setMessage('')
                    setStatusCode(result.data)

                })

            } else if(res.status === 403) {

                res.json().then(result => {
                    setStatusCode(result.data)
                })

            } else {

                setStatusCode("Fehler beim Senden")
            }

        } catch (err){
            console.log(err)
        }
    }


        
        

    

  return (
    <div>
        <div className="do-new-guestbook">

            <Link to="/guestbook" style={{marginTop: '20px'}}>Zurück zum Gästebuch</Link>
        </div>
        <div className="message" style={{textAlign: "center"}}>
            <br />{statusCode ? <p>{statusCode}</p> : null}
        </div>
        
        <div className="EnteryForm">
            <form onSubmit={onCaptchaExecute}>
                <input type="text" value={name} required={true} placeholder='Dein Name' onChange={(e) => setName(e.target.value)} /><br />
                <textarea type="input" value={message} required={true} name="eintrag" id="text" placeholder='Deine Nachricht' onChange={(e) => setMessage(e.target.value)}/>
                <div className="captcha-container" style={{textAlign: "center"}}>

                    <ReCAPTCHA
                        sitekey="6LdBxJMkAAAAAHwomgKjKJU8uugZ0Tj2aqjj2MWn"
                        theme="dark"
                        ref={recaptchaRef}
                        size="invisible"
                        onSubmit={handleSubmit}
                        

                    />

                </div>
                
                <button type="submit">Eintragen</button>
            </form>

            

            

            


        </div>

    </div>
  )
}

export default Eintragen