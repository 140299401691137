import React from 'react'
import Navbar from './components/NavBar/Navbar'
import Home from './components/home/Home'
const Homeindex = () => {
  return (
    <div>

      <Navbar active="true" />
      <Home />

    </div>
  )
}

export default Homeindex