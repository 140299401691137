import React, {useState} from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import './kontakt.css'
import { Helmet } from 'react-helmet'

function Kontakt(props) {

  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const recaptchaRef = React.createRef();

    const onCaptchaExecute = async (e) => {
        e.preventDefault();
        await recaptchaRef.current.executeAsync();
        try {
            const token = recaptchaRef.current.getValue();
            recaptchaRef.current.reset();
            console.log(props.WhichPack)
            let res = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    captcha: token,
                    message: message.replace(/\n/g, '<br />'),
                    WhichPack: props.WhichPack
                })
            })
            if (res.status === 200) {
                res.json().then(result => {
                    setName('')
                    setMessage('')
                    setEmail('')
                    setStatusCode(result.data)

                })

            } else if(res.status === 403) {
                res.json().then(result => {

                    setStatusCode(result.data)

                })
            } else {

                setStatusCode("Fehler beim Senden")
            }
        
    } catch (err){
        console.log(err)
    }

    }


  let handleSubmit = async (e) => {
    e.preventDefault()
    try {

        
        const token = recaptchaRef.current.getValue();
        if(!token) {
            recaptchaRef.current.execute();
            return
        } else {
            recaptchaRef.current.reset();
            let res = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    captcha: token,
                    message: message.replace(/\n/g, '<br />'),
                })
            })
            if (res.status === 200) {

                res.json().then(result => {
                    setName('')
                    setMessage('')
                    setEmail('')
                    setStatusCode(result.data)
    
                })
    
            } else if(res.status === 403) {
                res.json().then(result => {
                    
                    setStatusCode(result.data)
    
                })
            } else {
    
                setStatusCode("Fehler beim Senden")
            }
        }
        
        

    } catch (err){
        console.log(err)
    }
}


    
    



return (
<div>
    <Helmet>
        <title>MyDoula | Kontakt</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/kontakt" />
    </Helmet>
    <div className="do-new-guestbook">
        <h1 style={{textAlign: "center", marginTop: "50px", marginBottom: "50px", color: "#782c60"}}>Kontakt</h1>
    </div>
    <div className="EnteryForm" style={{marginTop: "50px"}}>
        <form onSubmit={onCaptchaExecute} className="contact-form">
            <input type="email" className="contact-form-input" value={email} required={true} placeholder='Deine Email' onChange={(e) => setEmail(e.target.value)} /><br />
            <input type="text" className="contact-form-input" value={name} required={true} placeholder='Dein Name' onChange={(e) => setName(e.target.value)} /><br />
            <textarea type="input" className="contact-form-input" value={message} required={true} name="eintrag" id="text" placeholder='Deine Nachricht' onChange={(e) => setMessage(e.target.value)}/>

            <div className="captcha-container" style={{textAlign: "center"}}>

                <ReCAPTCHA
                sitekey="6LdBxJMkAAAAAHwomgKjKJU8uugZ0Tj2aqjj2MWn"
                theme="dark"
                ref={recaptchaRef}
                size='invisible'
                onSubmit={handleSubmit}
                

                />

                </div>

            <button type="submit">Abschicken</button>
        </form>

        

        <div className="message" style={{textAlign: "center"}}>
            {statusCode ? <p>{statusCode}</p> : null}
        </div>

        


    </div>

</div>
)
}

export default Kontakt