import React from "react";
import "./ablauf.css";
import { Helmet } from 'react-helmet'

function Ablauf() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Ablauf</title>
        <meta name="description" content="MyDoula München - liebevolle und sehr erfahrene Begleitung während der Schwangerschaft und Geburt. Vereinbare jetzt ein Kennenlern - Treffen."></meta>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/ablaufderbegleitung" />
      </Helmet>
      <h1 className="overall-heading">
        Ablauf der Doula-Begleitung in München
        <br />
        Entspannt zur selbstbestimmten Geburt
      </h1>
      <h2 className="second-big" style={{color: "#782c60"}}>Kennenlern-Treffen</h2>
      <p className="normal-big">
        Nach der ersten Kontaktaufnahme (Telefon / E-Mail) treffen wir uns zu
        einem persönlichen Kennenlernen. Dabei kannst du entscheiden, ob ich
        die richtige Doula für dich bin.
      </p>
      <h2 className="second-big" style={{color: "#782c60"}}>Vortreffen als Hausbesuch</h2>
      <p className="normal-big">
        Wenn du dich für mich entschieden hast, vereinbaren wir zwei oder mehr
        weitere Treffen während der Schwangerschaft. Ich komme zu dir nach Hause, um dich und deinen Partner in entspannter und sicherer Umgebung auf die Geburt vorzubereiten. Diese Themen werden wir besprechen:
      </p>
      <ul className="ablauf-ul">
        <li>Ablauf der Geburt allgemein und speziell in deiner Klinik</li>
        <li>Möglichkeiten der Schmerzbehandlung</li>
        <li>Ausprobieren verschiedener Geburtspositionen und wichtige Tipps</li>
        <li>Beantwortung aller Fragen rund um Schwangerschaft und Geburt</li>
        <li>Wochenbett, Stillen und Ausprobieren verschiedener Stillpositionen mit einer Trainingspuppe</li>
        <li>Üben der Babypflege mit einer Trainingspuppe</li>
        <li>Beantwortung aller Fragen rund um Wochenbett, Stillen und Babypflege</li>
      </ul>
      <h2 className="second-big" style={{color: "#782c60"}}>Rufbereitschaft</h2>
      <p className="normal-big">
        Während der Schwangerschaft bleiben wir telefonisch in Kontakt, und du kannst mich bei Fragen oder Problemen jederzeit anrufen oder über WhatsApp / Signal kontaktieren!
        <br />
        <br />
        10 Tage vor dem errechneten Geburtstermin bis zum tatsächlichen Geburtsbeginn bin ich 24 Stunden am Tag für dich telefonisch erreichbar, um bei Geburtsbeginn zu dir ins Krankenhaus zu kommen (innerhalb von 2 Stunden nach deinem Anruf). Sollte die Geburt vor der Rufbereitschaft losgehen, komme ich so schnell wie möglich in die Klinik.
      </p>
      <h2 className="second-big" style={{color: "#782c60"}}>Geburtsbegleitung</h2>
      <p className="normal-big">
        Sobald die Geburt in Gang gekommen ist, komme ich zu dir in die Klinik und stehe dir zur Seite.
        <br />
        <br />
        Das bedeutet: ich atme mit dir, helfe dir, dich zu entspannen, massiere dich, tröste dich, ermutige dich, umsorge dich... je nachdem, was du gerade brauchst. Ich unterstütze dich bei der Umsetzung deiner Wünsche, damit du dich entspannt und ruhig auf das Geburtsgeschehen einlassen kannst.
        <br />
        <br />
        Das Wichtigste ist: DU BIST NICHT ALLEIN! Das schenkt dir Kraft und
        Zuversicht während der Geburt.
        <br />
        <br />
        Ich bleibe während der gesamten Dauer der Geburt bei dir.
        <br />
        <br />
        <br />
        Zusätzlich zu diesem Standardablauf besteht die Möglichkeit, weitere Termine zu vereinbaren:
      </p>
      <ul className="ablauf-ul">
        <li>zusätzliche Vorgespräche</li>
        <li>Nachgespräch nach der Geburt</li>
      </ul>
    </div>
  );
}

export default Ablauf;
