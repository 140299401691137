import React from 'react'
import { Helmet } from 'react-helmet'

function Hypnobirthing() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Hypnobirthing</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/hypnobirthing" />
      </Helmet>
      <div className="container-about">
        <h1 className="section-heading">Hypnobirthing</h1>
        <div className="hypnobirthing-container">
          <h5 style={{marginTop: "50px", color: "#782c60"}}>Was ist Hypnobirthing?</h5>
          <p style={{marginTop: "20px"}}>HypnoBirthing ist eine neue und natürliche Form, sich auf die Geburt vorzubereiten und den Wehen dadurch entspannter zu begegnen.<br /><br />HypnoBirthing bietet werdenden Eltern mit einem ganzheitlichen Konzept die Chance zu einer selbstbestimmten Geburt, die schmerzfrei sein kann. Die Methode ermöglicht es schwangeren Frauen, sich in einen angenehmen, tiefen Entspannungszustand zu bringen, um ihr Baby sanft und sicher zu gebären.</p>
          <h5 style={{marginTop: "50px", color: "#782c60"}}>Wie funktioniert Hypnobirthing?</h5>
          <p style={{marginTop: "20px"}}>Hierbei wird die Aufregung oder Angst vor der Geburt mit Hilfe von Hypnose-Techniken systematisch abgebaut, auch solche aus traumatisch erlebten Geburten. Viele Schmerzen entstehen so erst gar nicht.<br /><br />Die werdende Mutter lernt, sich tief zu entspannen und ihren Körper seine Aufgabe wesentlich besser erfüllen zu lassen. Zusätzlich vermindert HypnoBirthing Schmerzen durch besondere Atem-, Entspannungs- und Konzentrationsübungen drastisch, so dass Schmerzmittel häufig überflüssig sind. Ebenso wird die Zahl der Kaiserschnitte und anderer ärztlicher Eingriffe stark reduziert.<br /><br />Nach der Geburt erholen sich die Frauen deutlich schneller und können so auch besser eine innige Bindung zu ihrem Kind aufbauen.<br />Durch gezielte Weiterbildung im Bereich des HypnoBirthing kann ich Dich hierbei schon während der Schwangerschaft anleiten & begleiten und Dich unter der Geburt perfekt unterstützen.<br /><br /><br /><br />Während meiner langjährigen Tätigkeit als Doula habe ich inzwischen schon viele Frauen bei HypnoBirthing begleitet und kann dies sehr empfehlen!</p>
        </div>

      </div>
    </div>
  )
}

export default Hypnobirthing