import React from 'react'
import Navbar from './components/NavBar/Navbar'
import Footer from './components/footer/Footer'
import Uebermich from './components/uebermich/Uebermich'
import Home from './components/home/Home'
import Hypnobirthing from './components/hypnobirthing/Hypnobirthing'
import Guestbook from './components/guestbook/Guestbook'
import Kontakt from './components/kontakt/Kontakt'
import Wochenbett from './components/aufgaben/Wochenbett/Wochenbett'
import Beratungpervideo from './components/aufgaben/BeratungPerVideo/Beratungpervideo'
import Babypflege from './components/aufgaben/Babypflege/Babypflege'
import Paketeundpreise from './components/aufgaben/paketeundpreise/Paketeundpreise'
import Eintragen from './components/guestbook/Eintragen'
import GuestbookAdmin from './components/guestbook/GuestbookAdmin'
import Cookiepopup from './components/cookiepopup/Cookiepopup'
import Datenschutz from './components/datenschutz/Datenschutz'
import Impressum from './components/impressum/Impressum'
import Ablauf from './components/aufgaben/ablaufderbelgeitung/Ablauf'
import Error404 from './components/error/Error404'
import { Route, Routes} from 'react-router-dom'

const App = () => {
  return (
    <div>

        <Navbar />
        <div className="container">

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/uebermich" element={<Uebermich />} />
                <Route path="/paketeundpreise" element={<Paketeundpreise />} />
                <Route path="/ablaufderbegleitung" element={<Ablauf />} />
                <Route path="/beratungpervideo" element={<Beratungpervideo />} />
                <Route path="/babypflege" element={<Babypflege />} />
                <Route path="/wochenbett" element={<Wochenbett />} />
                <Route path="/hypnobirthing" element={<Hypnobirthing />} />
                <Route path="/guestbook" element={<Guestbook />} />
                <Route path="/kontakt" element={<Kontakt />} />
                <Route path="/create-guestbook" element={<Eintragen />} />
                <Route path="/admin/guestbook/mydoula" element={<GuestbookAdmin />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
            

        </div>
        <Cookiepopup />
        <Footer />

    </div>
  )
}

export default App