import React from "react";
import "./babypflege.css";
import { Helmet } from 'react-helmet'

const Babypflege = () => {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Babypflege</title>
        <meta name="description" content="MyDoula München - liebevolle und sehr erfahrene Begleitung während der Schwangerschaft und Geburt. Vereinbare jetzt ein Kennenlern - Treffen."></meta>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/babypflege" />
      </Helmet>
      <div>
        <div className="container babypflegecontainer">
          <div className="row">
            <div className="col-md-12 col-xxl-12">
              <h1
                style={{
                  textAlign: "center",
                  color: "#782c60",
                  marginBottom: "70px",
                }}
              >
                Babypflege - Schulung exclusiv bei Euch zuhause
              </h1>

              <p>
                Ganz entspannt in Eurem Wohnzimmer bekommt Ihr von mir eine
                Starthilfe für die ersten Tage & Wochen mit Eurem Baby. Wir
                unterhalten uns über die Anschaffung wichtiger Erstausstattungs
                - Artikel, die Schlafplatzgestaltung für das Baby und Ihr
                bekommt Tipps & Hilfen gegen „Kleine Beschwerden" wie z.B.
                Blähungen, Schnupfen, Schluckauf und wunder Baby - Po. Natürlich
                könnt Ihr hier auch in Ruhe all Eure Fragen rund um die
                Babypflege stellen.
              </p>
              <p style={{ marginTop: "30px" }}>
                <b>Hier die wichtigsten Punkte:</b>
              </p>
              <ul>
                <li>
                  Babypflege & Hygiene: Waschen / Baden, Wickeln, Hautpflege
                </li>
                <li>
                  Erstausstattung: was braucht man - was ist eher überflüssig?
                </li>
                <li>Umgang mit dem Baby: Halten, Hochnehmen, Tragen</li>
                <li>Stillpositionen: wie kann ich am besten stillen?</li>
                <li>Kleine Beschwerden: Blähungen, wunder Po, Schnupfen</li>
                <li>Sicheres Schlafen: Bettausstattung, Raumtemperatur</li>
                <li>Unruhe nach der Geburt – warum weinen Babys?</li>
                <p style={{ marginTop: "50px"}}>
                  Babypflege - Schulung exclusiv
                </p>
                <p>
                  <b>Hausbesuch ( Dauer ca. 2 Std. ) € 70,-</b>
                </p>
              </ul>
              

              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Babypflege;
