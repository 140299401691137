import React, { useState } from "react";
import "./cookiepopup.css";
import { useCookies } from "react-cookie";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const Cookiepopup = () => {
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(["cookiesaccepted"]);

  async function handleClick(event) {
    setCookie("cookiesaccepted", "true", { path: "/" });
    document.querySelector(".popup").style.display = "none";
  }

  return (
    <div>
      {cookies.cookiesaccepted === "true" ? null : (
        <div className="popup">
          <span className="message">
            Um Ihnen ein besseres Nutzererlebnis zu bieten, verwende ich
            Cookies. Durch die Nutzung meiner Website stimmen Sie der Verwendung
            von Cookies zu.
            <CustomLink
              to="/datenschutz"
              className="link-secondary footer-nav mehrerfahrencookie"
              style={{ marginLeft: "10px", listStyleType: "none" }}
            >
              Mehr Erfahren
            </CustomLink>
          </span>

          <div className="compliance">
            <a onClick={handleClick} className="cookie-button">
              Okay
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cookiepopup;

function CustomLink({ to, children, ...props }) {
  const resolevedPath = useResolvedPath(to);
  const match = useMatch({ path: resolevedPath.pathname, end: true });

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
}
