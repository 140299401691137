import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import gerbera from '../../images/gerbera.png'
import './guestbook.css'
import { Helmet } from 'react-helmet'

import ReactHtmlParser from 'react-html-parser'

function Guestbook() {

  const [data, setData] = React.useState([]);

  



  React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.data))
      .then(() => console.log());
  }, []);

  //function Test() {
//
//
  //  return (
  //    <div>
  //      <h1>Guestbook</h1>
  //        {data ? data.map((item) => (
  //          <div key={item.id}>
  //            <h1>{item.name} schreibt am {item.datum}</h1>
  //            <p>Nachricht: {item.message}</p>
//
  //          </div>
  //          
  //        )) : null}
//
//
  //      <Link to="/create-guestbook">Ins Gästebuch eintragen</Link>
  //    </div>
  //  )
//
  //}


  

  function GuestbookEntrys() {

    return (
      <div>
        <Helmet>
          <title>MyDoula | Erfahrungsberichte</title>
          <link rel="canonical" href="https://www.mydoula-muenchen.de/guestbook" />
        </Helmet>
        <h1 style={{textAlign: "center", marginTop: "50px", marginBottom: "50px", color: "#782c60"}}>Erfahrungsberichte</h1>
        <p style={{textAlign: "center", marginTop: "50px", color: "#32161F"}}>{data ? `bereits ${data.length} Erfahrungsberichte` : null}</p>
        <div className="do-new-guestbook">
          <Link to="/create-guestbook">Erfahrungsbericht erstellen</Link><br /><br />
          
        </div>
        <div className="container py-4 py-xl-5">

          <div className="row">
            <div className="col-md-9 col-xl-10 mx-auto p-10">
              {data ? data.map((item) => (
              
                
                  <div key={item.EintragNr} className="d-flex align-items-md-start neuer-eintrag" style={{paddingBottom: "30px", marginBottom: "50px"}}>
                    <div className="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center me-4 d-inline-block bs-icon xl gerbera-container">
                      <img title="Das Bild einer Gerbera Blume" src={gerbera} className="guestbook-gerbera" alt="Gerbera" style={{width: "100px", height: "100px"}}/>
                    </div>
                    <div>
                        <h5 style={{borderBottom: "0.2px solid #482728"}}><b>{item.Name}</b> schreibt am {item.Datum}</h5>
                        <p>{ReactHtmlParser(item.Message)}</p>
                    </div>
                  </div>
                  
                
                
              
              )).reverse() : null}
            </div>
            
          </div>
        </div>

      </div>
    )

  }

  return (
    <div>
      <GuestbookEntrys />
    </div>
  )
}

export default Guestbook