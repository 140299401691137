import React from 'react'
import './wochenbett.css'
import { Helmet } from 'react-helmet'
function Wochenbett() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Wochenbett</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/wochenbett" />
      </Helmet>
      <div>
        <div className="container wochenbettcontainer">
          <div className="row">
            <div className="col-md-12 col-xxl-12" >
              <h1 style={{textAlign: "center", color: "#782c60", marginBottom: "70px"}}>Unterstützung im Wochenbett</h1>
              
              <p>Als frischgebackene Mama mit einem Neugeborenen ist erst einmal alles neu und ungewohnt<br />Vielleicht wohnt Deine Familie weit weg und kann Dich nicht unterstützen, Dein Partner muss wieder arbeiten und Du fühlst Dich allein mit Baby überfordert?</p>
              <p style={{marginTop: "30px"}}><b>Gerne biete ich Dir hier als Doula und dreifache Mama meine Unterstützung an:</b></p>
              <h5 style={{marginTop: "40px", color: "#782c60"}}>Wochenbett - Hilfe</h5>
              <p>Gerne komme ich zu Dir nach Hause und übernehme für 1 - 2 Stunden Dein Baby.</p>
              <p>Du kannst einen Arztbesuch wahrnehmen, ein wenig Schlaf nachholen, ein ausgedehntes Bad nehmen...</p>
              <p>Gönne Dir eine kleine Auszeit und tanke Kraft & Energie! Ich kümmere mich inzwischen um Dein Baby.</p>
              <p>Ein paar hilfreiche Tipps & Tricks von einer erfahrenen Doula & Mama sind natürlich inklusive!</p>

              <ul>
                <li><b>1x Kosten € 55,- / 2 h</b></li>
                <li><b>5 er Karte: Kosten € 250,-</b></li>
                <li><b>10 er Karte: Kosten € 470,-</b></li>
              </ul>


              <p style={{marginTop: "50px"}}>Da ich keine Hebamme bin, kann ich keine Wochenbett - Nachsorge übernehmen!!</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wochenbett