import React from "react";
import { Helmet } from 'react-helmet'
const Error404 = () => {

  

  return (
    <div className="error404" style={{textAlign: "center", color: "#782c60"}}>
        <Helmet>
            <title>MyDoula | 404</title>
        </Helmet>
        <h1><b>404</b></h1>
        <h2><b>Diese Seite wurde nicht gefunden!</b></h2>

    </div>
  )
};

export default Error404;



