import React from 'react'
import { Helmet } from 'react-helmet'
import './home.css'
import women_laying_downImage from '../../images/women_laying_down.jpg'
function Home() {

  


  return (
    <div> 
      <Helmet>
        <title>MyDoula | Home</title>
        <meta name="description" content="MyDoula München - liebevolle und sehr erfahrene Begleitung während der Schwangerschaft und Geburt. Vereinbare jetzt ein Kennenlern - Treffen."></meta>
        <link rel="canonical" href="https://www.mydoula-muenchen.de" />
      </Helmet>

      <div>
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-xxl-12">
                <h1 style={{textAlign: "center", color: "#782c60"}}>Professionelle Doula Geburtsbegleitung in München</h1>
                <figure style={{textAlign: "center", marginTop: "42px"}}>
                    <blockquote className="blockquote">
                        <p className="mb-0">"Und jedem Anfang wohnt ein Zauber inne, der uns beschützt und der uns hilft, zu leben"</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">Hermann Hesse</figcaption>
                </figure>
                <img src={women_laying_downImage} alt="ss" id='womenlayingdownimage' />
                <h2 className="whydoula">Warum eine Doula?</h2>
                <p className="whydoula-text">Eine Doula bietet während der Schwangerschaft, Geburt und im Wochenbett persönliche und kontinuierliche Unterstützung. Im Vergleich zu einer Klinikhebamme, die mehrere Frauen gleichzeitig betreut und Schichtwechseln unterworfen ist, begleite ich dich als deine Doula exklusiv und individuell:<br /></p>
                <ul className="first-ul">
                    <li className="first-ul-item">Gemeinsam besprechen wir deine Wünsche und Vorstellungen rund um die Geburt, um ein individuelles Geburtserlebnis zu schaffen.<br /></li>
                    <li className="first-ul-item">Ich biete fundiertes Wissen zu Schwangerschaft, Geburt und Wochenbett, verfüge über medizinisches Hintergrundwissen und kenne die Abläufe in der Klinik.<br /></li>
                    <li className="first-ul-item">Ich betreue dich und deinen Partner während der gesamten Geburt, unabhängig von der Dauer, und unterstütze dich durch Atemübungen, Massagen, Trost und Ermutigung.<br /></li>
                </ul>
                <h2 className="hypnobirthing">HypnoBirthing & Friedliche Geburt</h2>
                <p className="hypnobirthing-text">HypnoBirthing und Friedliche Geburt sind innovative Methoden für eine natürliche und schmerzarme Geburtsvorbereitung. Ich helfe dir dabei, diese Methoden zu erlernen und begleite dich professionell während der Geburt.<br /></p>
                <h2 className="kaiserschnitt">Kaiserschnittbegleitung</h2>
                <p className="kaiserschnitt-text">Sollte ein Kaiserschnitt notwendig oder gewünscht sein, unterstütze und begleite ich dich auch hierbei einfühlsam, um Ängste abzubauen und für ein entspanntes Geburtserlebnis zu sorgen.<br /></p>
                <h2 className="vaterwerden">Unterstützung für werdende Väter</h2>
                <p className="vaterwerden-text">Werdende Väter profitieren von der Doula-Begleitung, indem sie in dieser neuen Situation Unterstützung erhalten und entlastet werden. Sollte dein Partner bei der Geburt nicht anwesend sein können, biete ich als vertraute Begleitperson zusätzliche Sicherheit.<br /></p>
                <h2 className="second-ul-text1">Die Vorteile einer Doula-Begleitung:<br /></h2>
                <p className="second-ul-text2">Wissenschaftliche Studien von Marshall H. Klaus und John H. Kennel haben gezeigt, dass durch die Anwesenheit einer Doula:<br /></p>
                <ul className="second-ul">
                <li className="second-ul-item">die Selbstbestimmung der Frau und ihr Wohlergehen gefördert werden<br /></li>
                <li className="second-ul-item">die Gebärende sich besser entspannen kann<br /></li>
                <li className="second-ul-item">die Dauer der Geburt um bis zu 25% verkürzt wird<br /></li>
                <li className="second-ul-item">die Kaiserschnittrate um 50% gesenkt werden kann<br /></li>
                <li className="second-ul-item">die Nachfrage nach PDA/Schmerzmitteln um 60% reduziert werden kann<br /></li>
                <li className="second-ul-item">der Stillerfolg erhöht werden kann<br /></li>
                <li className="second-ul-item">die Mutter-Kind-Bindung gefördert wird<br /></li>
                <li className="second-ul-item">der werdende Vater entlastet und unterstützt wird<br /></li>
                </ul>
                
                <em>Quelle: „Doula - Der neue Weg der Geburtsbegleitung“, Marshall H. Klaus, John H. Kennel, Phyllis H. Klaus<br /><br /></em>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>

  )
}

export default Home