import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import gerbera from '../../images/gerbera.png'
import ReactHtmlParser from 'react-html-parser'
import ReCAPTCHA from "react-google-recaptcha";

function GuestbookAdmin() {

    const [statusCode, setStatusCode] = useState('');
    const [password, setPassword] = useState('');
    const [access, setAccess] = useState(false);
    const [data, setData] = React.useState([]);
    const [deleteEntryStatus, setDeleteEntryStatus] = useState('');

    React.useEffect(() => {
        fetch("/api")
          .then((res) => res.json())
          .then((data) => setData(data.data))
          .then(() => console.log());
      }, []);

    const recaptchaRef = React.createRef();

    const onCaptchaExecute = async (e) => {

        e.preventDefault()
        await recaptchaRef.current.executeAsync();
        const password = document.getElementById('password').value
        try {

            const token = recaptchaRef.current.getValue();
            recaptchaRef.current.reset();

            let res = await fetch('/api/check-guestbook-pw', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: password,
                    captcha: token
                })
            })
            if (res.status === 200) {

                

                setAccess(true)

            } else if(res.status === 403) {

                setStatusCode("Passwort Falsch")
                setAccess(false)
            } else {
                setAccess(false)
                setStatusCode("Fehler beim Senden")
            }

        } catch (err){
            console.log(err)
        }

    }

    let handleSubmit = async (e) => {
        e.preventDefault()
        const password = document.getElementById('password').value
        try {

            const token = recaptchaRef.current.getValue();
            recaptchaRef.current.reset();

            let res = await fetch('/api/check-guestbook-pw', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password: password,
                    captcha: token
                })
            })
            if (res.status === 200) {

                

                setAccess(true)

            } else if(res.status === 403) {

                setStatusCode("Passwort Falsch")
                setAccess(false)
            } else {
                setAccess(false)
                setStatusCode("Fehler beim Senden")
            }

        } catch (err){
            console.log(err)
        }
    }


    let removeEntry = async (e) => {
        e.preventDefault()

        

        try {
                
                let res = await fetch('/api/remove-guestbook-entry', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: e.target.getAttribute('data-key')
                    })
                })
                if (res.status === 200) {
                    setData(data.filter(item => item.EintragNr !== e.target.getAttribute('data-key')))
                    
                    res.json().then(result => {
                        setDeleteEntryStatus(result.data)
                    })
    
                } else {
    
                    setDeleteEntryStatus("Fehler")
                }
    
        } catch (err) {
            console.log(err)
        }
    }

    


        
        

    

  return (
    <div>

        {!access ? <div>
            <form onSubmit={onCaptchaExecute}>
                <input type="password" value={password} name="password" id="password" placeholder='Passwort' onChange={(e) => setPassword(e.target.value)}/>
                <div className="captcha-container" style={{textAlign: "center"}}>

                    <ReCAPTCHA
                        sitekey="6LdBxJMkAAAAAHwomgKjKJU8uugZ0Tj2aqjj2MWn"
                        theme="dark"
                        ref={recaptchaRef}
                        size="invisible"
                        onSubmit={handleSubmit}

                    />

                </div>
                <button type="submit">Submit</button>
            </form>


        </div> : <div>

        
        <h1 style={{textAlign: "center"}}>
            <Link to="/guestbook" style={{marginTop: '20px', fontSize: "20px"}}>Zurück zum Gästebuch</Link><br /><br />
            Willkommen im Admin bereich
        </h1>
        <div className="message" style={{textAlign: "center"}}>
                <h4><br />{deleteEntryStatus ? <p>{deleteEntryStatus}</p> : null}</h4>
        </div>

        <h5 style={{textAlign: "center", marginTop: "70px"}}>{data.length < 1 ? 'Keine Einträge' : null}</h5>
            
        <div className="container py-4 py-xl-5">

            <div className="row">
              <div className="col-md-9 col-xl-10 mx-auto p-10">
                {data ? data.map((item) => (
                
                    
                    <div key={item.EintragNr} className="d-flex align-items-md-start neuer-eintrag" style={{paddingBottom: "30px", marginBottom: "50px"}}>
                      <div className="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center me-4 d-inline-block bs-icon xl gerbera-container">
                        <img title="Das Bilder einer Gerbera Blume" src={gerbera} className="guestbook-gerbera" alt="Gerbera" style={{width: "100px", height: "100px"}}/>
                      </div>
                      <div>
                          
                          <h4 style={{borderBottom: "0.2px solid rgba(166, 166, 166, .5)"}}>{item.EintragNr}. {item.Name} schreibt am {item.Datum}</h4>
                          <p>{ReactHtmlParser(item.Message)}</p>
                      </div>
                      <form onSubmit={removeEntry} data-key={item.EintragNr}>
                        
                        <button type="submit" key={item.EintragNr} style={{marginLeft: "10px"}}>Löschen</button>
                      </form>
                      
                    </div>

                
                
                
                )).reverse() : null}
              </div>
                
            </div>
            </div>
            
        </div>}

    </div>
  )
}

export default GuestbookAdmin