import React from "react";
import "./navbar.css";
import "bootstrap";
import topbanner from "../../images/top-banner.png";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
const Navbar = () => {
  async function handleNavClick() {
    const toggler = document.getElementById("navcol-3");
    if (toggler.classList.contains("show")) {
      toggler.classList.remove("show");
    } else {
      return;
    }
  }

  async function navbarTogglerHandler() {
    const toggler = document.getElementById("navcol-3");
    if (toggler.classList.contains("show")) {
      toggler.classList.remove("show");
    } else if (!toggler.classList.contains("show")) {
      toggler.classList.add("show");
    }
  }

  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-md py-3">
        <div className="container">
          <a href="#" className="navbar-brand d-flex align-items-center">
            <span>MyDoula</span>
          </a>
          <button
            onClick={navbarTogglerHandler}
            data-bs-toggle="collapse"
            className="navbar-toggler"
            data-bs-target="#navcol-5"
          >
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navcol-3">
            <ul className="navbar-nav mx-auto">
              <CustomLink to="/" onClick={handleNavClick}>
                Home
              </CustomLink>
              <CustomLink to="/uebermich" onClick={handleNavClick}>
                Über mich
              </CustomLink>
              <li className="nav-item dropdown">
                {" "}
                <Link
                  className="dropdown-toggle nav-link"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  to="#"
                >
                  Begleitung
                </Link>
                <div className="dropdown-menu">
                  <CustomLinkDropDown
                    to="/ablaufderbegleitung"
                    onClick={handleNavClick}
                  >
                    Ablauf der Begleitung
                  </CustomLinkDropDown>
                  <CustomLinkDropDown
                    to="/beratungpervideo"
                    onClick={handleNavClick}
                  >
                    Beratung per Videochat
                  </CustomLinkDropDown>
                  <CustomLinkDropDown to="/babypflege" onClick={handleNavClick}>
                    Babypflege-Schulung
                  </CustomLinkDropDown>
                  <CustomLinkDropDown to="/wochenbett" onClick={handleNavClick}>
                    Wochenbett-Unterstützung
                  </CustomLinkDropDown>
                  <CustomLinkDropDown
                    to="/paketeundpreise"
                    onClick={handleNavClick}
                  >
                    Pakete & Preise
                  </CustomLinkDropDown>
                </div>
              </li>
              <CustomLink to="/hypnobirthing" onClick={handleNavClick}>
                HypnoBirthing
              </CustomLink>
              <CustomLink to="/guestbook" onClick={handleNavClick}>
                Erfahrungsberichte
              </CustomLink>
              <CustomLink to="/kontakt" onClick={handleNavClick}>
                Kontakt
              </CustomLink>
            </ul>
          </div>
        </div>
      </nav>

      <div className="top-banner-container1">
        <div className="container top-banner-container2">
          <img
            className="top-banner-logo"
            src={topbanner}
            alt="MyDoula Logo Banner"
            title="Das Logo von MyDoula"
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

function CustomLink({ to, children, ...props }) {
  const resolevedPath = useResolvedPath(to);
  const match = useMatch({ path: resolevedPath.pathname, end: true });

  return (
    <li className="nav-item">
      <Link
        to={to}
        {...props}
        className={match ? "nav-link active" : "nav-link"}
      >
        {children}
      </Link>
    </li>
  );
}

function CustomLinkDropDown({ to, children, ...props }) {
  const resolevedPath = useResolvedPath(to);
  const match = useMatch({ path: resolevedPath.pathname, end: true });

  return (
    <Link
      to={to}
      {...props}
      className={match ? "dropdown-item active" : "dropdown-item"}
    >
      {children}
    </Link>
  );
}
