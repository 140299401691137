import React, { useState } from 'react'
import './paketeundpreise.css'
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Kontakt from '../../kontakt/Kontakt';
import { Helmet } from 'react-helmet'

const Paketeundpreise = () => {

    const [WhichPack, setWhichPack] = useState("");
  

  return (
    <div>
      <Helmet>
        <title>MyDoula | Pakete und Preise</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/paketeundpreise" />
      </Helmet>

      <div className="container py-4 py-xl-5">
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2 style={{color: "#782c60"}}>Pakete und Preise</h2>
            <h3 style={{fontSize: "20px", fontWeight: "400"}}><br />Wenn du eines meiner Pakete buchst, kannst du gegenüber einer individuellen Buchung sparen.<br /><br/>Jederzeit kannst du dir die gewünschten Leistungen selbst zusammenstellen. Bitte melde dich hierzu unter <a href="mailto:birgit@mydoula-muenchen.de">birgit@mydoula-muenchen.de</a></h3>
            <h3 style={{fontSize: "20px", fontWeight: "400"}}><br />Zubuchen weiterer Leistungen bei jedem Paket möglich!</h3>
          </div>
          
        </div>
        <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-2">
            <div className="col">
            <div className="card border-self border-2 h-100">
                    <div className="card-body d-flex flex-column justify-content-between p-4"><span className="badge bg-self position-absolute top-0 end-0 rounded-bottom-left text-uppercase">Am beliebtesten</span>
                        <div>
                            <h6 className="text-uppercase card-group-name">Paket 1</h6>
                            <h4 className="display-6 fw-bold" >€ 950,-</h4>
                            
                            <hr />
                            <ul className="list-unstyled">
                                <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                        </svg></span><span>Rufbereitschaft & Geburtsbegleitung (€ 650,-)</span></li>
                                <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                    </svg></span><span>1x Ersttreffen (€ 60,-)</span></li>
                                <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                    </svg></span><span>2x Vortreffen (je Treffen € 120,-)</span></li>
                            </ul>
                        </div><CustomLink to="/kontakt" onClick={() => {
                          <Kontakt WhichPack = {setWhichPack("Doula - Begleitung")} />

                        }} className="btn btn-primary d-block w-100 preis-button" role="button" href="#">Kennenlerntreffen vereinbaren</CustomLink>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card h-100">
                    <div className="card-body d-flex flex-column justify-content-between p-4">
                        <div>
                            <h6 className="text-uppercase card-group-name">Paket 2<br/><k>Geeignet beim 2. Kind</k></h6>
                            <h4 className="display-6 fw-bold">€ 810,-</h4>
                            <hr />
                            <ul className="list-unstyled">
                                <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                        </svg></span><span>Rufbereitschaft & Geburtsbegleitung (€ 650,-)</span></li>
                                <li className="d-flex mb-2"><span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                        </svg></span><span>1x Vortreffen XXL (€ 160,-)</span></li>
                            </ul>
                            
                        </div><CustomLink to="/kontakt" onClick={() => {
                          <Kontakt WhichPack = {setWhichPack("Doula - Mini")} />

                        }} className="btn btn-primary d-block w-100 preis-button" role="button" href="#">Kennenlerntreffen vereinbaren</CustomLink>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>









  )
}

export default Paketeundpreise

function CustomLink({ to, children, ...props }) {
  const resolevedPath = useResolvedPath(to);

  return (
    <p className="nav-item">
      <Link to={to} {...props}>
        {children}
      </Link>
    </p>
  );
}