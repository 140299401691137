import React from 'react'
import './beratungpervideo.css'
import { Helmet } from 'react-helmet'
function Beratungpervideo() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Beratung per Video</title>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/beratungpervideo" />
      </Helmet>

<div>
            <div className="container videochatcontainer">
              <div className="row">
                <div className="col-md-12 col-xxl-12" >
                  <h1 style={{textAlign: "center", color: "#782c60", marginBottom: "70px"}}>Liebe Schwangere</h1>
                  
                  <p>Ab sofort könnt ihr bei Interesse folgende exklusive Videochat - Info - Gespräche bei mir buchen, in denen ich euch Tipps & Informationen & Hilfestellung zu verschiedenen Themenbereichen anbieten möchte:</p>
                  <div className="video-1 video-all">
                    <h4>1) Wissenswertes rund um die Geburt in der von euch ausgewählten Klinik</h4>
                    <p>Beratung bei der Klinikwahl, wie läuft es speziell in dieser Klinik ab und welche Möglichkeiten habe ich vor Ort?</p>
                   
                    <h6><b>( Kosten € 49,- / Dauer 60 min oder € 75,- / Dauer 90 min  )</b></h6>
                  </div>
                  <div className="video-1 video-all">
                    <h4>2) Gut vorbereitet in den Kreißsaal gehen</h4>
                    <p>Was kommt in die Kliniktasche? Welche Positionen sind hilfreich? Was kann mein Partner mir Gutes tun unter der Geburt?</p>
                    
                    <h6><b>( Kosten € 49,- / Dauer 60 min oder € 75,- / Dauer 90 min )</b></h6>
                  </div>
                  <div className="video-1 video-all">
                    <h4>3) Individuelle Beratung</h4>
                    <p>Hast Du viele Fragen zu Schwangerschaft und Geburt? Hier gibt es Hilfe zu allen Themen!</p>
                    
                    <h6><b> ( Kosten € 49,- / Dauer 60 min oder € 75,- / Dauer 90 min )</b></h6>
                  </div>

                  <p>Buchung eines Termins bitte über Email <a href="mailto:birgit@mydoula-muenchen.de">birgit@mydoula-muenchen.de</a> oder über Whatsapp unter <a href="tel:0178 / 2702690">0178 / 2702690</a></p>
                  <p style={{textAlign: "center", marginTop: "40px", fontSize: "30px"}}><b>Ich freue mich auf Euch !</b></p>
                </div>
                
              </div>
            </div>
          </div>

    </div>
  )
}

export default Beratungpervideo