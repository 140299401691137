import React from 'react'
import bk_selfi from '../../images/userpic_5.png'
import './uebermich.css'
import { Helmet } from 'react-helmet'
function Uebermich() {
  return (
    <div>
      <Helmet>
        <title>MyDoula | Über mich</title>
        <meta name="description" content="MyDoula München - liebevolle und sehr erfahrene Begleitung während der Schwangerschaft und Geburt. Vereinbare jetzt ein Kennenlern - Treffen."></meta>
        <link rel="canonical" href="https://www.mydoula-muenchen.de/uebermich" />
      </Helmet>
      <div className="container-about">
        <h1 className="section-heading">Über Doula Birgit Kürten</h1>
        <div className="bk-selfi-container">
          <img title="Ein selfie von Birgit Kürten / MyDoula" src={bk_selfi} alt="Birgit Kürten - Doula in München und Umgebung" className="bk-selfi"/>
        </div>
        <div className="about-container1">
          <p>Mein Name ist Birgit Kürten, ich bin Mutter von drei Söhnen und lebe in Kirchheim / Münchner Osten. Als erfahrene Doula biete ich Geburtsbegleitung und Unterstützung für werdende Mütter und ihre Familien in München und Umgebung.</p>
        </div>
        
        <div className="about-container2">
          <h4 className="about-heading" style={{color: "#782c60"}}>Ausbildung und Erfahrung in der Geburtsbegleitung</h4>
          <p>Die 1,5-jährige Ausbildung zur Doula habe ich bis November 2010 im Zentrum für natürliche Geburt in München absolviert (Zertifikat: GfG-Doula®). Während der Doula Ausbildung konnte ich wertvolle Erfahrungen sammeln:</p>
          <ul className="about-ul">
            <li>mehrwöchiges Vollzeitpraktikum in der Münchner Frauenklinik Dr. Geisenhofer</li>
            <li>Begleitung mehrerer Geburten</li>
            <li>Hospitationen in verschiedenen Kursen: Geburtsvorbereitung, Stilltreff, Babymassage...</li>
            <li>Teilnahme am GfG - Seminar "Stillen"</li>
            <li>Laufende Teilnahme an Vorträgen und Workshops, u.a. zu den Themen: „Pränataldiagnostik“, „Hausgeburt“, "Gut vorbereitet und entspannt ins Wochenbett", "Stillen ab der ersten Lebensstunde", "Tod am Lebensanfang“,etc.</li>
            <li>Mitglied bei der GfG – Gesellschaft für Geburtsvorbereitung</li>
          </ul>
        </div>
        <div className="about-container3">
          <p className="since2010">Seit November 2010 bin ich als freiberufliche Doula in München und im Münchner Umland tätig und unterstütze Familien während Schwangerschaft, Geburt und Wochenbett.</p>
        </div>
      </div>

    </div>
  )
}

export default Uebermich